<script setup>
import { ShoppingCart } from '@element-plus/icons-vue';

import { toRefs } from 'vue'
const props = defineProps({
  info: String,
})
const {info} =toRefs(props)

</script>

<template>
    <ul class="goods-list">
        <li v-for="item in info" :key="item.id">
            <RouterLink to="/" class="link-to">
                <div class="main-img">
                    <el-image :src="item.picture" :key="item.id" fit="fill"
                        style="width: 100%;height: 100%;border-radius: 20px; " lazy></el-image>
                </div>

                <p class="name">{{ item.name }}</p>
                <div class="price-father">
                    <text class="price">{{ item.price }} USDT</text>
                    <text class="original-price">{{ item.originalPrice }} USDT</text>
                </div>
                <div>
                    <p class="discount">{{ item.discount }}</p>
                </div>
                <div class="last-line">
                    <el-rate v-model=item.rate disabled show-score text-color="#ff9900"
                        score-template="{value} points"></el-rate>
                    <el-button type="warning" :icon="ShoppingCart" size="large">
                    </el-button>
                </div>
            </RouterLink>
        </li>
    </ul>
    <div class="pagination">
        <el-pagination layout="prev, pager, next" :total="1000" background />
    </div>
</template>

<style scoped lang='scss'>
/* Float Shadow */
li {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

li:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    /* W3C */
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
}

li:hover,
li:focus,
li:active {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    /* move the element up by 5px */
}

li:hover:before,
li:focus:before,
li:active:before {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}


.goods-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;

    li {
        height: 545px;
        width: 350px;
        margin: 5px 5px 5px 5px;
        padding: 5px 5px 5px 5px;
        border-radius: 20px;
        background: #0d2929;

        .link-to {
            border-radius: 20px;

            // background: white;
            .price-father {
                display: flex;
                line-height: 20px;
                color: gray;
                margin-left: 10px;

                .price {
                    color: $mainColor;
                    font-size: x-large;
                    font-weight: bold;
                }

                .original-price {
                    margin-left: 20px;
                    text-decoration: line-through;
                }
            }

            .discount {
                border-radius: 20px;
                border-style: solid;
                border-color: orange;
                width: 70px;
                text-align: center;
            }

            .last-line {
                display: flex;
                justify-content: space-between;
                margin-right: 10px;
                margin-left: 10px;
            }


        }
    }


}

p {
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 10px;

}

.pagination {
    display: flex;
    justify-content: center;
}</style>