import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { getCategory } from '@/api/testAPI';
import '@/styles/common.scss'
import vue3GoogleLogin from 'vue3-google-login'
import * as Sentry from "@sentry/vue";

getCategory().then(res => {
  console.log(res)
})
const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://dd45cc2ad24dcd608f088c3e5d9b6db8@o4506067013337088.ingest.sentry.io/4506067015565312",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(createPinia())
app.use(router)

app.use(vue3GoogleLogin, {
  clientId: '532785860570-a4rc1up1q8bvcp0eld7t17785e7gsod2.apps.googleusercontent.com'
})

app.mount('#app')
