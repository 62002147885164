<script setup>

</script>

<template>
  <div class="back">
    <div class="recommend">
      <div class="img">
        <img class="img-a" src="/src/assets/images/coin.png" alt="Cryptocurrency">
      </div>
      <div class="text">
        <span>Purchase with</span>
        <br>
        <span>Cryptocurrency</span>
      </div>
    </div>
    <div class="recommend">
      <div class="img">
        <img class="img-a" src="/src/assets/images/merchant.png" alt="Merchant">
      </div>
      <div class="text">
        <span>Becoming A</span>
        <br>
        <span>Merchant</span>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
.back {
  background-color: #0d1e1e;
  display: flex;
  justify-content: space-around;

  .recommend {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    // margin: 5px 100px 5px 100px;
    .img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding-left: 100px;
    }

    .img-a {
      height: 30px;
      margin: auto;
    }

    .text {
      margin-left: 20px;
      font-size: 15px;
      color: $assistantColor;
      font-weight: bolder;
    }

  }
}
</style>


