<script setup>
import { ref } from 'vue'
import VueTurnstile from 'vue-turnstile';
import { checkEmail } from '@/api/user'
const centerDialogVisible = ref(false)
const form = ref({
    email: '',
    password: '',
    token: ''
})
const rules = {
    email: [
        { required: true, message: 'Email cannot be empty', trigger: 'blur' }
    ],
    password: [
        { required: true, message: 'Password cannot be empty' },
        { min: 6, max: 64, message: 'Password length requires 6-64 characters' }
    ],
}

function querySearchEmail(queryString, callback) {
    const emailList = [
        { value: '@gmail.com' },
        { value: '@outlook.com' },
        { value: '@yahoo.com' },
        { value: '@protonmail.com' },
        { value: '@aol.com' },
        { value: '@mail.com' },
        { value: '@inbox.com' },
        { value: '@gmx.com' }
    ]
    let results = []
    let queryList = []
    emailList.map(item => {
        queryList.push({ value: queryString.split('@')[0] + item.value })
    })
    results = queryString ? queryList.filter(createFilter(queryString)) : queryList;
    callback(results);
}
function createFilter(queryString) {
    return (item) => {
        return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    };
}
const callback = (response) => {
    console.log("Google返回 = ", response)
}

const formRef = ref(null)
const doCheckEmail = () => {
    const { email, token } = form.value
    formRef.value.validate(async (valid) => {
        console.log("valid = " + valid)
        if (valid) {
            const res = await checkEmail({ email, token })
            console.log(res)
        }
    })
}
</script>


<template>
    <div class="outer" @click="centerDialogVisible = true">
        <img src="@/assets/images/user.png" alt="user">
        <a href="#">Sign in / Register</a>
    </div>

    <el-dialog v-model="centerDialogVisible" width="500px" center append-to-body="true">
        <template #header="{ titleId, titleClass }">
            <h4 :id="titleId" :class="titleClass">Sign in / Register</h4>
        </template>
        <div class="content">
            <GoogleLogin :callback="callback" class="google-login"></GoogleLogin>
            <div class="brs"></div>
            <el-divider>Manual login</el-divider>
            <el-form class="form" ref="formRef" :model="form" :rules="rules">
                <text>Please enter your Email</text>
                <el-form-item prop="email">
                    <el-autocomplete class="email-input" v-model="form.email" placeholder="Please enter your Email"
                        :fetch-suggestions="querySearchEmail" style="width: 400px; margin: 10px 0px 10px 0px;"
                        popper-class="my-autocomplete" />
                </el-form-item>
                <div class="password-div">
                    <text>Please enter your password</text>
                    <el-form-item prop="password">
                        <el-input v-model="form.password" class="password-input" placeholder="Please input" />
                    </el-form-item>
                </div>
            </el-form>
            <vue-turnstile site-key="0x4AAAAAAAL5SbplC3rbRFCT" v-model="form.token" theme="light" action="register" />
            <el-button type="primary" round @click="doCheckEmail" size="large">Next</el-button>
        </div>


        <!-- <template #footer>
            <span class="dialog-footer">
                <el-button @click="centerDialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="centerDialogVisible = false">
                    Confirm
                </el-button>
            </span>
        </template> -->
    </el-dialog>
</template>


<style scoped lang='scss'>
.dialog-footer button:first-child {
    margin-right: 10px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .brs {
        margin-top: 20px;
    }

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;

        text {
            font-size: larger;
            font-weight: bolder;
        }

        el-autocomplete {
            display: flex;
            flex-direction: column;
        }


        .password-input {
            width: 400px;
            margin: 10px 0px 10px 0px;
        }

        .password-div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.outer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        height: 25px;
        width: 25px;
    }

    a {
        margin-left: 5px;
    }
}
</style>
  
<style lang='scss'>
.my-autocomplete {
    ul {
        flex-direction: column;
    }
}
</style>