<script setup>
import { useScroll } from '@vueuse/core';
import Login from '@/views/Profile/components/Login.vue';
const { y } = useScroll(window)
</script>

<template>
  <header class="app-header" :class="{ show: y > 100 }">
    <div class="container">
      <div>
        <img class="logo" src="@/assets/images/logo.png" alt="CYpay">
      </div>
      <nav class="nav">
        <ul>
          <li><img src="/src/assets/images/categories.png" alt="categories"><a href="#">Categories</a></li>
          <li><img src="/src/assets/images/hot.png" alt="categories"><a href="#">Hot Sales</a></li>
          <li><img src="/src/assets/images/like.png" alt="categories"><a href="#">Most Favorite</a></li>
          <li><img src="/src/assets/images/new.png" alt="categories"><a href="#">Popular</a></li>
          <div class="maker"></div>
        </ul>
      </nav>

      <div class="search">
        <form>
          <input placeholder="show someting">
          <div>
            <img src="/src/assets/images/search.png" alt="search">
          </div>
        </form>
      </div>
      <div class="right-side">
        <div class="user r-block">
          <template v-if="false">
          </template>
          <template v-else>
            <Login ></Login>
          </template>
        </div>
        <div class="support r-block">
          <div>
            <img src="/src/assets/images/support.png" alt="support">
          </div>
          <a href="#">Support</a>
        </div>
        <div class="cart r-block">
          <div>
            <img src="/src/assets/images/cart.png" alt="cart">
          </div>
          <a href="#">Cart</a>
        </div>

      </div>
    </div>
  </header>
</template>


<style scoped lang='scss'>
.app-header {
  background: $BGmainColor;
  display: flex;
  justify-content: center;

  &.show {
    position: fixed;
    width: 100%;
    height: 80px;
    top: -80px;
    z-index: 999;
    transition: all 0.3s linear;
    transform: translateY(100%);
    opacity: 0.9;
  }

  .container {
    max-width: 1470px;

    .right-side {
      display: flex;
      justify-content: flex-end;
      width: 350px;
      margin-left: 10px;

      .r-block {
        margin-left: 15px;
        margin-right: 15px;
        opacity: 0.7;
        transition: 0.25s;
        cursor: pointer;

        &:hover {
          opacity: 1;

        }
      }

      img {
        width: 25px;
      }

      a {
        margin-left: 10px;
      }
    }

    .search {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 20px;

      form {
        display: flex;
        flex: 1;

        input {
          padding-left: 10px;
          padding-right: 50px;
          height: 40px;
        }

        div {
          height: 40px;
          width: 40px;
          margin: 1px 1px 1px -42px;
          border-radius: 20px;
          background-color: $BGmainColor;
          cursor: pointer;

          img {
            margin: auto;
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    .nav {
      display: flex;
      align-items: center;

      ul {
        flex-direction: row;

        li {
          z-index: 1;
          margin-left: 10px;
          opacity: 0.7;
          transition: 0.25s;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }

          .maker {
            position: absolute;
            top: 0;
            width: 50px;
            transition: 0.5s;
            z-index: 2;
          }

          .maker::before {
            display: flex;
            align-items: center;
            content: '';
            position: absolute;
            top: 0;
            left: 50px;
            transform: translateX(-50px);
            width: 50px;
            height: 40px;
            background: $assistantColor;
          }

          img {
            height: 15px;
            margin: auto;
          }

          a {
            padding: 10px 15px;
            font-weight: bold;

          }
        }
      }
    }
  }

  .logo {
    width: 100px;
    cursor: pointer;
  }



}
</style>