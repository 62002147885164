import httpInstance from '@/utils/http';

export const checkEmail = ({token,email})=>{
    return httpInstance({
        url:'/isRegistered',
        method:'post',
        data:{
            token,
            email
        }
    })
}