<script setup>
import { ref } from 'vue';
const bannerList = ref([])
bannerList.value = [{ id: '1', imgUrl: 'https://img.cypay.one/banner1.jpg' },
{ id: '2', imgUrl: 'https://img.cypay.one/banner2.jpg' },
{ id: '3', imgUrl: 'https://img.cypay.one/banner3.jpg' }]

</script>


<template>
    <div class="home-banner">
        <el-carousel interval='8000' type="card" height="270px">
            <el-carousel-item v-for="item in bannerList" :key="item.id">
                <img :src="item.imgUrl" alt="">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>


<style scoped lang='scss'>
.home-banner {
    margin-top: 20px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
    }
}
</style>