<script setup>
import ProductCard from './ProductCard.vue';
// import fingers from '../../../utils/fingerPrint';
import { ref, onMounted } from 'vue'
import { googleOneTap } from "vue3-google-login"
onMounted(() => {
  googleOneTap()
    .then((response) => {
      // This promise is resolved when user selects an account from the the One Tap prompt
      console.log("Handle the response", response)
    })
    .catch((error) => {
      console.log("Handle the error", error)
    })

  // const [code, finger] = fingers()
  // console.log(code)
  // console.log(finger)

  // // 获取RTCIP
  // try {
  //   var RTCPeerConnection = window.RTCPeerConnection;
  // } catch {
  //   RTCPeerConnection;
  // }
  // if (RTCPeerConnection) {
  //   var peerConn = new RTCPeerConnection({
  //     'iceServers': [{
  //       'urls': ['stun:stun.l.google.com:19302']
  //     }]

  //   });

  //   peerConn.createDataChannel('test'); // Needs something added for some reason
  //   peerConn.createOffer({}).then((desc) => peerConn.setLocalDescription(desc));
  //   peerConn.onicecandidate = (e) => {
  //     if (e.candidate == null) {
  //       // document.getElementById("ip").innerText = /c=IN IP4 ([^\n]*)\n/.exec(peerConn.localDescription.sdp)[1];
  //       console.log(/c=IN IP4 ([^\n]*)\n/.exec(peerConn.localDescription.sdp)[1])
  //     }
  //   };
  // } else {
  //   // Inform user that webrtc fetch failed
  //   // document.getElementById("ip").innerText = 'Failed to fetch IP via WebRTC, perhaps your WebRTC is disabled?';
  //   console.log("failed")
  // }


})

const newList = ref([])


newList.value = [{
  id: 1, picture: "https://img.kwcdn.com/product/Fancyalgo/VirtualModelMatting/89b32c9054c671a445e2a61fa2d5aaac.jpg?imageView2/2/w/500/q/80/format/webp",
  name: '1pc EVA Neck And Shoulder Relaxer, C-Shape Cervical Traction Device, Neck Relax And Cervical Spine Alignment, Chiropractic Pillow Neck Stretcher Massager',
  price: '18.99', originalPrice: '99.88', discount: '-88%', rate: 4.4
}, {
  id: 2, picture: "https://img.kwcdn.com/product/Fancyalgo/VirtualModelMatting/e47d6545d8f1cfc990d317e543ce7a16.jpg?imageView2/2/w/500/q/80/format/webp",
  name: "Winter plush lined flat shoes, Women's low top slip on shoes, women's footwear",
  price: '42.11', originalPrice: '299.88', discount: '-58%', rate: 3.5
}, {
  id: 3, picture: "https://img.kwcdn.com/product/Fancyalgo/VirtualModelMatting/d1a4dc3049683b7ff5f6d6e051081644.jpg?imageView2/2/w/500/q/80/format/webp",
  name: "1/2pcs Mini Positive Potato, Cute Wool Knitting Doll With Positive Card, Funny Knitted Potato Doll Xmas New Year Gift Decoration",
  price: '12.45', originalPrice: '992.88', discount: '-98%', rate: 4.0
}]

</script>

<template>
  <!-- <iframe id="iframe" sandbox="allow-same-origin" style="display: none"></iframe> -->
  <div class="home-panel">
    <ProductCard :info="newList"></ProductCard>
  </div>
</template>


<style scoped lang='scss'></style>